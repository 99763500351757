import React, { useRef, useState, useEffect } from "react";
import "../../Styles/style.css";
import Button from "@mui/material/Button";
import ImageModal from "../imagePreviewModal";
import axios from "axios";
import LoadingModal from "../../modals/loading-modal";
import Modal from "@mui/material/Modal";
import ImageIcon from "@mui/icons-material/Image";
import { Alert, Box, Snackbar } from "@mui/material";
import carSkeleton from "../../../images/car-skeleton.png";
import { Form } from "react-bootstrap";
import baseUrl from "../../../api/ipconfig";
import InnerLoader from "../../loader/innerLoader";
import { openDB } from "idb";
import imageCompression from "browser-image-compression";
import s3 from "../../../config/s3Config";

const Step9 = ({ onSuccess, previouse, user, userData, step2, setStep2 }) => {
  const token = localStorage.getItem("token");

  const formRef = useRef(null);
  const btnRef = useRef(null);
  // const imageRef = useRef(null);
  // const clickMarkerRef = useRef(null);
  const [images, setImages] = useState({});

  const [defects, setDefects] = useState({});
  const [defectImage, setDefectImage] = useState(null);
  const [stepImages, setStepImages] = useState(null);

  const [imageLoading, setImageLoading] = useState(false);
  const [imageID, setImageId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingFields, setIsSubmittingFields] = React.useState(false);
  const [openList, setOpenList] = useState(false);
  const [openCarSkeleton, setOpenCarSkeleton] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [pending, setPending] = useState(false);
  const [pendingData, setPendingData] = useState("");
  const [prevImg, setPrevImg] = useState(null);
  const [openImageGallery, setOpenImageGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);

  const [frontRightFenderDefectBtn, setFrontRightFenderDefectBtn] =
    useState(false);
  const [bonnetDefectTypes, setBonnetDefectTypes] = useState([]);
  const [frontBumperDefectTypes, setFrontBumperDefectTypes] = useState([]);
  const [rearRightFenderDefectTypes, setRearRightFenderDefectTypes] = useState(
    []
  );
  const [frontRightFenderDefectTypes, setFrontRightFenderDefectTypes] =
    useState([]);
  const [frontRightDoorDefectTypes, setFrontRightDoorDefectTypes] = useState(
    []
  );
  const [rearRightDoorDefectTypes, setRearRightDoorDefectTypes] = useState([]);
  const [RightFootBandDefectTypes, setRightFootBandDefectTypes] = useState([]);

  const [defectedAreaPic, setDefectedAreaPic] = useState("");
  const [partId, setPartId] = useState("");

  const [bonnetDefectBtn, setBonnetDefectBtn] = useState(false);
  const [frontBumperDefectBtn, setFrontBumperDefectBtn] = useState(false);

  const [frontRightDoorDefectBtn, setFrontRightDoorDefectBtn] = useState(false);
  const [rearRightDoorDefectBtn, setRearRightDoorDefectBtn] = useState(false);
  const [rightFootBandDefectBtn, setRightFootBandDefectBtn] = useState(false);
  const [defectId, setDefectId] = useState(null);

  const [errOpen, setErrOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [allDefects, setAllDefects] = useState([]);

  const [rearRightFenderDefectBtn, setRearRightFenderDefectBtn] =
    useState(false);
  const [isSycc, setIsSync] = useState(null);

  const extractTokenFromUrl = (url, paramName) => {
    const urlSearchParams = new URLSearchParams(url);
    return urlSearchParams.get(paramName);
  };

  const url = window.location.search;

  const StepToken = extractTokenFromUrl(url, "token");
  const syncable = extractTokenFromUrl(url, "sync");

  useEffect(() => {
    setTimeout(() => {
      setIsSync(syncable);
    }, 2500);
  }, [syncable]);

  // const getAllDefects = async () => {
  //   const url = window.location.search;
  //   const StepToken = extractTokenFromUrl(url, "token");
  //   try {
  //     const response = await axios.get(`${baseUrl}/getalldefect`, {
  //       params: {
  //         iinitial_token: StepToken,
  //         inspectionstep_id: 2,
  //       },
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     if (response?.status === 200) {
  //       if (response?.data?.bonnet_defect) {
  //         setBonnetDefectTypes(response?.data?.bonnet_defect);
  //       } else {
  //         setBonnetDefectTypes([]);
  //       }

  //       if (response?.data?.frontBumper_defect) {
  //         setFrontBumperDefectTypes(response?.data?.frontBumper_defect);
  //       } else {
  //         setFrontBumperDefectTypes([]);
  //       }

  //       if (response?.data?.offfrontwing_defect) {
  //         setFrontRightFenderDefectTypes(response?.data?.offfrontwing_defect);
  //       } else {
  //         setFrontRightFenderDefectTypes([]);
  //       }

  //       if (response?.data?.offfrontdoor_defect) {
  //         setFrontRightDoorDefectTypes(response?.data?.offfrontdoor_defect);
  //       } else {
  //         setFrontRightDoorDefectTypes([]);
  //       }

  //       if (response?.data?.offbackdoor_defect) {
  //         setRearRightDoorDefectTypes(response?.data?.offbackdoor_defect);
  //       } else {
  //         setRearRightDoorDefectTypes([]);
  //       }

  //       if (response?.data?.rightfootboard_defect) {
  //         setRightFootBandDefectTypes(response?.data?.rightfootboard_defect);
  //       } else {
  //         setRightFootBandDefectTypes([]);
  //       }

  //       if (response?.data?.offbackwing_defect) {
  //         setRearRightFenderDefectTypes(response?.data?.offbackwing_defect);
  //       } else {
  //         setRearRightFenderDefectTypes([]);
  //       }
  //     }
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  const filterDefectsByField = (allDefects) => {
    //console.log("allDefects === > ", allDefects);

    const filteredDefects = allDefects.filter(
      (defect) => defect.initial_token === StepToken
    );

    const filteredDefectsByStep = filteredDefects.filter(
      (defect) => defect.inspectionstep_id === 2
    );

    setAllDefects(filteredDefectsByStep);

    try {
      const bonnetDefects = filteredDefects?.filter(
        (defect) => defect.defect_place === "bonnet_defect"
      );
      const frontBumperDefects = filteredDefects?.filter(
        (defect) => defect.defect_place === "frontBumper_defect"
      );
      const frontRightFenderDefects = filteredDefects?.filter(
        (defect) => defect.defect_place === "offfrontwing_defect"
      );
      const frontRightDoorDefects = filteredDefects?.filter(
        (defect) => defect.defect_place === "offfrontdoor_defect"
      );
      const rearRightDoorDefects = filteredDefects?.filter(
        (defect) => defect.defect_place === "offbackdoor_defect"
      );
      const rightFootboardDefects = filteredDefects?.filter(
        (defect) => defect.defect_place === "rightfootboard_defect"
      );
      const rearRightFenderDefects = filteredDefects?.filter(
        (defect) => defect.defect_place === "offbackwing_defect"
      );

      if (bonnetDefects.length > 0) {
        setBonnetDefectTypes(bonnetDefects);
        //console.log("bonnetDefects === > ", bonnetDefectTypes);
      } else {
        setBonnetDefectTypes([]);
      }

      if (frontBumperDefects.length > 0) {
        setFrontBumperDefectTypes(frontBumperDefects);
        //console.log("frontBumperDefects === > ", frontBumperDefectTypes);
      } else {
        setFrontBumperDefectTypes([]);
      }

      if (frontRightFenderDefects.length > 0) {
        setFrontRightFenderDefectTypes(frontRightFenderDefects);
      } else {
        setFrontRightFenderDefectTypes([]);
      }

      if (frontRightDoorDefects.length > 0) {
        setFrontRightDoorDefectTypes(frontRightDoorDefects);
        //console.log("frontRightDoorDefects === > ", frontRightDoorDefectTypes);
      } else {
        setFrontRightDoorDefectTypes([]);
      }

      if (rearRightDoorDefects.length > 0) {
        setRearRightDoorDefectTypes(rearRightDoorDefects);
        //console.log("rearRightDoorDefects === > ", rearRightDoorDefectTypes);
      } else {
        setRearRightDoorDefectTypes([]);
      }

      if (rightFootboardDefects.length > 0) {
        setRightFootBandDefectTypes(rightFootboardDefects);
        //console.log("rightFootboardDefects === > ", RightFootBandDefectTypes);
      } else {
        setRightFootBandDefectTypes([]);
      }

      if (rearRightFenderDefects.length > 0) {
        setRearRightFenderDefectTypes(rearRightFenderDefects);
      } else {
        setRearRightFenderDefectTypes([]);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    const formData = new FormData();

    const fetchDraftPending = async () => {
      formData.append("iinitial_token", StepToken);
      formData.append("inspectionstep_id", 2);

      try {
        const response = await axios.post(
          `${baseUrl}/inspectionstepdetails`,
          formData,
          {
            // params: {
            //   iinitial_token: StepToken,
            //   inspectionstep_id: 3,
            // },
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        //console.log(response?.data?.initial, "draft step interior");
        const formData2 = response?.data?.initial;
        populateFormWithResponse(formData2);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      // getAllDefects();
    };

    fetchDraftPending();
  }, [token]);

  const populateFormWithResponse = (formData) => {
    if (!formData || typeof formData !== "object") {
      console.error("Invalid formData:", formData);
      return;
    }

    const formElements = formRef.current.elements;

    Object.keys(formData).forEach((fieldName) => {
      const field = formElements[fieldName];

      if (field) {
        field.value = formData[fieldName];
      }
    });
  };

  const handleDeleteImage = async (ind, id) => {
    try {
      const db = await openDB("my-database", 1);

      // Open a transaction to access the object store
      const tx = db.transaction("images", "readwrite");
      const store = tx.objectStore("images");

      // Delete the image with the specified id
      await store.delete(id);

      const updatedGalleryImages = [...galleryImages];
      updatedGalleryImages.splice(ind, 1);

      setGalleryImages(updatedGalleryImages);

      // After deletion, update the local images
      await getLocalImages();
    } catch (error) {
      //console.log("delete image error", error);
      setErrOpen(true);
      setError("Couldn't delete image");
    } finally {
      await getLocalImages();
    }
  };

  useEffect(() => {
    if (galleryImages.length === 0) {
      setOpenImageGallery(false);
    }
  }, [galleryImages.length]);

  const postImages = async () => {
    if (stepImages?.length > 0) {
      for (const image of stepImages) {
        try {
          setIsSubmitting(true);
          // const uploadEndpoint = `${baseUrl}/saveinspectionimage`;
          // const formData = new FormData();

          // // Append common form data fields
          // formData.append("iinitial_token", StepToken);
          // formData.append("inspectionstep_id", 2);

          // // Append each image to formData
          // formData.append(`file[]`, image.image);
          // formData.append(`image_name`, image.name); // Assuming name is an array

          // //console.log("FormData:", formData.get("image_name"));

          // const response = await axios.post(uploadEndpoint, formData, {
          //   headers: {
          //     Authorization: `Bearer ${token}`,
          //     "Content-Type": "multipart/form-data",
          //   },
          // });

          const s3Key = `public/inspection/${StepToken}/${image?.name}/${image?.image?.name}`;

          const params = {
            Bucket: "famewheels-pvt",
            Key: s3Key,
            Body: image.image,
            // ACL: 'public-read', // Or the appropriate ACL
            ContentType: image.image.type,
          };

          const response = await s3.upload(params).promise();

          const url = response.Key;

          const ImageName = url.substring(url.lastIndexOf("/") + 1);

          const formData = new FormData();
          formData.append("image_name", image?.name);
          formData.append("iinitial_token", StepToken);
          formData.append("inspectionstep_id", 2);
          formData.append(`file`, ImageName);

          const responseApi = await axios.post(
            `${baseUrl}/saveinspectionimage`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          //console.log("API Response:", response.data);

          await handleDeleteImage(image.id, image.id);
        } catch (error) {
          console.log(error);
        } finally {
          setIsSubmitting(false);
        }
      }
    }
  };

  const getLocalImages = async () => {
    try {
      setImageLoading(true);
      const db = await openDB("my-database", 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains("images")) {
            db.createObjectStore("images", {
              keyPath: "id",
              autoIncrement: true,
            });
          }
        },
      });

      if (!db) {
        console.error("IndexedDB database not found.");
        return;
      }

      const tx = db.transaction("images", "readonly");
      const store = tx.objectStore("images");
      const imageData = await store.getAll();
      await tx.done;

      const filteredImagesData = imageData.filter(
        (image) => image.token === StepToken
      );

      const updatedImages = await filteredImagesData.reduce(
        async (accPromise, image) => {
          const acc = await accPromise;

          if (!acc[image.name]) {
            acc[image.name] = [];
          }

          const base64Image = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(image.image);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });

          acc[image.name].push({ ...image, image: base64Image });
          return acc;
        },
        Promise.resolve({})
      );

      setImages((prevState) => ({
        ...updatedImages,
      }));

      // ******************************* do not remove this comment **********************************

      // //console.log("Filtered images data:", filteredImagesData);

      const imagesOfStep = await filteredImagesData.filter(
        (images) => images?.inspection_step === 2
      );

      await setStepImages(imagesOfStep);
    } catch (error) {
      console.error("Error retrieving images:", error);
      setError(error);
    } finally {
      setImageLoading(false);
    }
  };

  useEffect(() => {
    if (StepToken) {
      const formData = localStorage.getItem(`${StepToken}_second`);
      if (formData) {
        const newData = JSON.parse(formData);

        //console.log("newData === >>>> ", newData);
        setTimeout(() => {
          populateFormWithResponse(newData);
        }, 0);
      } else {
        return;
      }
    }
  }, [populateFormWithResponse]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const form = formRef.current;
      if (form) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formRef]);

  // const getDefects = async () => {
  //   const url = window.location.search;
  //   const StepToken = extractTokenFromUrl(url, "token");
  //   try {
  //     const response = await axios.get(`${baseUrl}/getdefectimages`, {
  //       params: {
  //         iinitial_token: StepToken,
  //         defect_place: partId,
  //       },
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     if (response.status === 200) {
  //       if (response?.data?.defect_place === "bonnet_defect") {
  //         setBonnetDefectTypes(response?.data?.defect_rows);
  //       }

  //       if (response?.data?.defect_place === "frontBumper_defect") {
  //         setFrontBumperDefectTypes(response?.data?.defect_rows);
  //       }

  //       if (response?.data?.defect_place === "offfrontwing_defect") {
  //         setFrontRightFenderDefectTypes(response?.data?.defect_rows);
  //       }

  //       if (response?.data?.defect_place === "offfrontdoor_defect") {
  //         setFrontRightDoorDefectTypes(response?.data?.defect_rows);
  //       }

  //       if (response?.data?.defect_place === "offbackdoor_defect") {
  //         setRearRightDoorDefectTypes(response?.data?.defect_rows);
  //       }

  //       if (response?.data?.defect_place === "rightfootboard_defect") {
  //         setRightFootBandDefectTypes(response?.data?.defect_rows);
  //       }

  //       if (response?.data?.defect_place === "offbackwing_defect") {
  //         setRearRightFenderDefectTypes(response?.data?.defect_rows);
  //       }
  //     }
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  const handleDefectImageChange = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const newImages = Array.from(files).map((file) => {
        const reader = new FileReader();
        setDefectImage(file);
        return new Promise((resolve) => {
          reader.onloadend = () => {
            resolve(file.slice()); // Store the Blob in prevImg
            setPrevImg(reader.result); // You can still set prevImg for preview
          };
          reader.onerror = (error) => {
            console.error("Error reading file:", error);
            resolve(null); // Resolve with null on error
          };
          reader.readAsDataURL(file);
        });
      });
    }
  };

  const getAllDefectsFromDB = async () => {
    try {
      const db = await openDB("defects-database", 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains("defects")) {
            const objectStore = db.createObjectStore("defects", {
              keyPath: "id",
              autoIncrement: true,
            });
          }
        },
      });
      //console.log("Database opened successfully:", db);

      const transaction = db.transaction("defects", "readonly");
      //console.log("Transaction started:", transaction);

      const objectStore = transaction.objectStore("defects");
      //console.log("Object store retrieved:", objectStore);

      const stored = await objectStore.getAll();

      //console.log("stored defects", stored);

      transaction.oncomplete = function () {
        //console.log("Transaction completed");
        // Do something with the retrieved data, like updating state or displaying it in the UI
      };

      if (stored) {
        filterDefectsByField(stored);
      }
    } catch (error) {
      console.error("Error retrieving defects from IndexedDB:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getAllDefectsFromDB();
        await getLocalImages();
      } catch (error) {
        console.error("Error fetching defects:", error);
      }
    };

    fetchData();
  }, []);

  const defectsTypes = async () => {
    try {
      // Update defects state and perform IndexedDB operations
      setDefects((prevDefects) => {
        const newDefects = {
          ...prevDefects,
          [partId]: [
            ...(prevDefects[partId] || []),
            {
              defect_place: partId,
              defect_type: defectedAreaPic,
              initial_token: StepToken,
              inspectionstep_id: 2,
              file: defectImage || null, // Include file field only when there's a valid image
            },
          ],
        };

        // Open connection to IndexedDB
        openDB("defects-database", 1, {
          upgrade(db) {
            if (!db.objectStoreNames.contains("defects")) {
              const objectStore = db.createObjectStore("defects", {
                keyPath: "id",
                autoIncrement: true,
              });
            }
          },
        })
          .then(async (db) => {
            // Store defects for each dynamic key
            const transaction = db.transaction("defects", "readwrite");
            const objectStore = transaction.objectStore("defects");
            const defectsArray = newDefects[partId];
            for (const defect of defectsArray) {
              const addRequest = objectStore.add(defect);
              addRequest.onsuccess = function (event) {
                //console.log("Defect added to IndexedDB");
              };
              addRequest.onerror = function (event) {
                console.error(
                  "Error adding defect to IndexedDB: " + event.target.errorCode
                );
              };
            }
            await transaction.done;

            // Call getAllDefectsFromDB after adding all defects
            setDefects({});
            await getAllDefectsFromDB();

            return newDefects;
          })
          .catch((error) => {
            console.error("Error storing defects in IndexedDB:", error);
            setDefects({});
            return prevDefects; // Return previous state if there's an error
          });

        return newDefects;
      });
    } catch (error) {
      console.error("Error updating defects state:", error);
    }
    setDefectImage(null);
    setDefectedAreaPic("");
  };

  const getImages = async (fieldName) => {
    setGalleryImages(images[fieldName]);
  };

  const handleImageChange = async (e, fieldName) => {
    const files = Array.from(e.target.files);

    try {
      const db = await openDB("my-database", 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains("images")) {
            db.createObjectStore("images", {
              keyPath: "id",
              autoIncrement: true,
            });
          }
        },
      });

      for (const file of files) {
        try {
          //console.log("Original file:", file);

          const options = {
            maxSizeMB: 0.06,
            maxWidthOrHeight: 1024,
            useWebWorker: true,
          };

          const compressedFile = await imageCompression(file, options);
          //console.log("Compressed file:", compressedFile);

          const compressedImageFile = new File([compressedFile], file.name, {
            type: file.type,
          });

          const imageData = {
            name: fieldName,
            image: compressedImageFile,
            token: StepToken,
            inspection_step: 2,
          };

          const tx = db.transaction("images", "readwrite");
          const store = tx.objectStore("images");
          await store.add(imageData);
          await tx.done;
          //console.log("Image uploaded to IndexedDB successfully");
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      }

      await getLocalImages();
    } catch (error) {
      console.error("IndexedDB error:", error);
    }
  };

  const deleteDefect = async () => {
    try {
      setPending(true);
      setPendingData("Deleting Defect");

      const db = await openDB("defects-database", 1);

      // Open a transaction to access the object store
      const tx = db.transaction("defects", "readwrite");
      const store = tx.objectStore("defects");

      // Delete the image with the specified id
      await store.delete(defectId?.id);

      await getAllDefectsFromDB();

      setOpenDeleteModal(false);
    } catch (error) {
      //console.log(error);
    } finally {
      setPending(false);
      setPendingData("");
    }
  };

  const handleDeleteDefect = (type) => {
    setOpenDeleteModal(true);
    setDefectId(type);
  };

  const handleImageGallery = () => {
    setOpenImageGallery(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  const previewOpen = (image) => {
    setIsModalOpen(true);
    setModalImage(image);
  };

  const handeDefectsSave = async () => {
    if (allDefects?.length > 0) {
      for (const defect of allDefects) {
        try {
          setIsSubmitting(true);

          const s3Key = `public/defect/${StepToken}/${defect.defect_place}/${defect.defect_type}/${defect.file.name}`;

          const params = {
            Bucket: "famewheels-pvt",
            Key: s3Key,
            Body: defect.file,
            // ACL: 'public-read', // Or the appropriate ACL
            ContentType: defect.file.type,
          };

          const responseImage = await s3.upload(params).promise();

          const formData = new FormData();

          formData.append("defect_place", defect.defect_place);
          formData.append("defect_type", defect.defect_type);
          formData.append("iinitial_token", StepToken);
          formData.append("inspectionstep_id", 2);
          if (responseImage?.Key) {
            formData.append("file", responseImage?.Key);
          }

          const response = await axios.post(`${baseUrl}/defects`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });

          // if (response?.data) {
          await deleteDefectById(defect.id);
          setSuccessOpen(true);
          // setSuccess(response?.data);
          // }
        } catch (error) {
          setError(error.response.data?.error);
          console.log(error);
          setErrOpen(true);
        } finally {
          setIsSubmitting(false);
        }
      }
    }
  };

  const deleteDefectById = async (defectId) => {
    try {
      const db = await openDB("defects-database", 1);
      const tx = db.transaction("defects", "readwrite");
      const store = tx.objectStore("defects");
      await store.delete(defectId);
      await tx.done; // Using complete to ensure the transaction is fully committed
      //console.log('Defect deleted from IndexedDB');
    } catch (error) {
      console.error("Error deleting defect from IndexedDB:", error);
    }
  };

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      const formId = extractTokenFromUrl(url, "form_id");

      const CreateUser = async () => {
        // const imageFile = dataURLtoBlob(images.ibody_rediatorcoreimage);

        const formData = new FormData(formRef.current);

        // Convert formData to JSON object
        const formDataObject = {};
        formData.forEach((value, key) => {
          formDataObject[key] = value;
        });

        // Serialize form data to JSON string
        const serializedFormData = JSON.stringify(formDataObject);

        const inspectionDraftArray = JSON.parse(
          localStorage.getItem("inspection_draft")
        );

        inspectionDraftArray.forEach((obj) => {
          // Check if the token matches the one you want to overwrite
          if (obj.token === StepToken) {
            obj.step = "2";
            obj.token = StepToken;
          }
        });

        //console.log("inspectionDraftArray === ?>>> ",inspectionDraftArray);

        localStorage.setItem(
          "inspection_draft",
          JSON.stringify(inspectionDraftArray)
        );

        // Store serialized form data in localStorage

        localStorage.setItem(`${StepToken}_second`, serializedFormData);
        localStorage.setItem(`stepId`, 2);
        if (formRef.current) {
          const params = {
            data: new FormData(formRef.current),
          };
          params.data.append("userId", userData?.id);
          params.data.append(
            "iinitial_id",
            formId ? formId : previouse?.iinitial_id
          );
          params.data.append("iinitial_token", StepToken);
        }
        setStep2({
          rightMirror: null,
          rightAPillar: null,
          leftHeadlightCondition: null,
          rightHeadlightCondition: null,
          radiatorCoreSupport: null,
          leftFrontRail: null,
          rightFrontRail: null,
          cowlPanelFirewall: null,
          frontSubFrame: null,
          leftStrutPowerApon: null,
          rightStrutPowerApon: null,
          frontWindShield: null,
          terminalsCondition: null,
          voltage: null,
          hoses: null,
          bonnetButton: null,
          trunkButton: null,
          fuelCapButton: null,
          engineOilLevel: null,
          wiringHarness: null,
          beltsFan: null,
          beltsAc: null,
          radiator: null,
          frontRightBPillar: null,
          rearRightCPillar: null,
          rearRightDPillar: null,
          bonnet: null,
          frontBumper: null,
          frontRightFender: null,
          frontRightDoor: null,
          rearRightDoor: null,
          rightFootBand: null,
          rearRightFender: null,
          rightTailLightCondition: null,
        });
        onSuccess();
      };
      if (formRef.current) {
        btnRef.current?.setLoader(true);
        try {
          setPending(true);
          setPendingData("Completing Step 2");
          await CreateUser();
        } catch (e) {
          console.error(e);
          // Handle error if needed
        } finally {
          setPending(false);
          setIsSubmitting(false);
        }
      }
    },
    [onSuccess, previouse?.iinitial_token, token, user?.id, images, StepToken]
  );

  const handleSaveFields = async () => {
    setIsSubmitting(true);
    setIsSubmittingFields(true);

    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    const formId = extractTokenFromUrl(url, "form_id");

    const CreateUser = async () => {
      // const imageFile = dataURLtoBlob(images.ibody_rediatorcoreimage);

      if (formRef.current) {
        const params = {
          data: new FormData(formRef.current),
        };
        params.data.append("userId", userData?.id);
        params.data.append(
          "iinitial_id",
          formId ? formId : previouse?.iinitial_id
        );
        params.data.append("iinitial_token", StepToken);
        // params.data.append("is_edit", 0);

        // params.data.append("imageFile", imageFile);
        // Object.entries(images).forEach(([fieldName, file]) => {
        //   params.data.append(fieldName, dataURLtoBlob(file));
        // });
        try {
          const response = await axios.post(
            `${baseUrl}/savebodyinspection`,
            params?.data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          //console.log("====================================");
          //console.log(response);
          //console.log("====================================");

          if (response?.data) {
            setSuccessOpen(true);
            setSuccess("Step 2 Completed");
            setTimeout(() => {
              onSuccess();
            }, 500);
          }
          // localStorage.removeItem(`${StepToken}_second`);

          setIsSubmitting(false);
          formRef.current.hasUnsavedChanges = false;
        } catch (e) {
          console.error(e);
          setErrOpen(true);
          setError(`Step 2 Submit Failed!`);
          setIsSubmitting(false);
          return;
        } finally {
          btnRef.current?.setLoader(false);
        }
      }
    };
    if (formRef.current) {
      btnRef.current?.setLoader(true);
      try {
        setPending(true);
        setPendingData("Completing Step 2");
        await CreateUser();
      } catch (e) {
        console.error(e);
        // Handle error if needed
      } finally {
        setPending(false);
        setIsSubmittingFields(false);
        setIsSubmitting(false);
      }
    }
  };

  const [showSave, setShowSave] = useState(false);

  const handleSave = async () => {
    try {
      await postImages();
      //console.log("pics saved")

      await handeDefectsSave();
      //console.log("defects saved")
      await handleSaveFields();
    } catch (error) {
      //console.log(error)
    }
  };
  const defectsArr = [
    {
      value: "P-0.55",
      name: "P",
    },
    {
      value: "A1-0.005",
      name: "A1",
    },
    {
      value: "A2-0.0314786",
      name: "A2",
    },
    {
      value: "A3-0.056",
      name: "A3",
    },
    {
      value: "B1-0.005",
      name: "B1",
    },
    {
      value: "B2-0.0314786",
      name: "B2",
    },
    {
      value: "B3-0.056",
      name: "B3",
    },
    {
      value: "C1-0.005",
      name: "C1",
    },
    {
      value: "C2-0.022508",
      name: "C2",
    },
    {
      value: "D1-0.005",
      name: "D1",
    },
    {
      value: "D2-0.0314786",
      name: "D2",
    },
    {
      value: "D3-0.056",
      name: "D3",
    },
    {
      value: "E1-0",
      name: "E1",
    },
    {
      value: "E2-0",
      name: "E2",
    },
    {
      value: "E3-0",
      name: "E3",
    },
    {
      value: "S1-0.005",
      name: "S1",
    },
    {
      value: "S2-0.022508",
      name: "S2",
    },
    {
      value: "W1-0",
      name: "W1",
    },
    {
      value: "W2-0",
      name: "W2",
    },
    {
      value: "W3-0",
      name: "W3",
    },
    {
      value: "Y1-0.005",
      name: "Y1",
    },
    {
      value: "Y2-0.022508",
      name: "Y2",
    },
    {
      value: "Y3-0.03",
      name: "Y3",
    },
    {
      value: "X-0.03",
      name: "X",
    },
    {
      value: "XX-0",
      name: "XX",
    },
    {
      value: "H-0.03004",
      name: "H",
    },
  ];

  useEffect(() => {
    if (isSycc == "true") {
      setTimeout(() => {
        handleSave();
      }, 2000);
    }
    //console.log("sync ===", syncable)
  }, [isSycc]);

  useEffect(() => {
    if (step2.frontBumper === "Not Ok") {
      setFrontBumperDefectBtn(true);
    }

    if (step2.bonnet === "Not Ok") {
      setBonnetDefectBtn(true);
    }

    if (step2.frontRightFender === "Not Ok") {
      setFrontRightFenderDefectBtn(true);
    }

    if (step2.frontRightDoor === "Not Ok") {
      setFrontRightDoorDefectBtn(true);
    }

    if (step2.rearRightDoor === "Not Ok") {
      setRearRightDoorDefectBtn(true);
    }

    if (step2.rightFootBand === "Not Ok") {
      setRightFootBandDefectBtn(true);
    }

    if (step2.rearRightFender === "Not Ok") {
      setRearRightFenderDefectBtn(true);
    }
  }, []);

  const updateCarPart = (part, value) => {
    setStep2((prevState) => {
      const updatedStep2 = {
        ...prevState,
        [part]: value,
      };
      localStorage.setItem(`${StepToken}_second`, JSON.stringify(updatedStep2));
      return updatedStep2;
    });
  };

  return (
    <>
      <LoadingModal
        open={isSubmitting}
        // onClose={() => setIsSubmitting(false)}
      />

      <Snackbar
        open={errOpen}
        autoHideDuration={4000}
        onClose={() => setErrOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={successOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSuccessOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {success}
        </Alert>
      </Snackbar>

      <Snackbar
        open={pending}
        onClose={() => setPending(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setPending(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          {pendingData}
        </Alert>
      </Snackbar>

      {/* save modal  */}

      <Modal
        open={showSave}
        onClose={() => setShowSave(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="sm-modal position-relative p-3 p-md-4">
          <Button
            className="text-white border-0 text-center fw-600"
            color="error"
            variant="contained"
            onClick={postImages}
            fullWidth
          >
            Save Images
          </Button>

          <Button
            className="text-white border-0 text-center fw-600"
            color="error"
            variant="contained"
            onClick={handleSave}
            fullWidth
          >
            Save Defects and Fields
          </Button>
        </Box>
      </Modal>

      {/* skeleton modal  */}

      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="sm-modal position-relative p-3 p-md-4">
          <p className="text-center">
            Are You Sure You Want To Delete{" "}
            {defectId?.defect_type.split("-")[0]} Defect ?
          </p>

          <Button
            className="bg-danger text-white border-0 text-center fw-600"
            onClick={deleteDefect}
            fullWidth
          >
            Delete
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openCarSkeleton}
        onClose={() => setOpenCarSkeleton(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="sm-modal position-relative p-3 p-md-4">
          <div className="position-relative">
            <img
              src={carSkeleton}
              className=" object-fit-contain w-100"
              alt="car-skeleton"
            />

            {bonnetDefectTypes && (
              <div
                onClick={() => partId === "bonnet_defect" && setOpenList(true)}
                className="border-1 d-flex gap-1 p-2 border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "32.5%",
                  bottom: "16%",
                  width: "35%",
                  height: "17%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {bonnetDefectTypes &&
                    bonnetDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={type?.id}
                          className="bg-danger rounded-pill text-white border-0 text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                            zIndex: 99999,
                          }}
                        >
                          {type?.defect_type.split("-")[0]}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {frontBumperDefectTypes && (
              <div
                onClick={() =>
                  partId === "frontBumper_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2 border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "29%",
                  bottom: "0%",
                  width: "42%",
                  height: "12%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {frontBumperDefectTypes &&
                    frontBumperDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="rounded-pill text-white text-center fw-600 bg-danger"
                          style={{
                            padding: "2px",
                            width: "20px",
                            height: "20px",
                            fontSize: "10px",
                            zIndex: 99999,
                          }}
                        >
                          {type.defect_type.split("-")[0]}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {frontRightFenderDefectTypes && (
              <div
                onClick={() =>
                  partId === "offfrontwing_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2 border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "13%",
                  bottom: "15%",
                  width: "10%",
                  height: "18%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {frontRightFenderDefectTypes &&
                    frontRightFenderDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="rounded-pill text-white text-center fw-600 bg-danger"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type.split("-")[0]}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {frontRightDoorDefectTypes && (
              <div
                onClick={() =>
                  partId === "offfrontdoor_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2 border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "5%",
                  bottom: "34%",
                  width: "18.5%",
                  height: "18%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {frontRightDoorDefectTypes &&
                    frontRightDoorDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="rounded-pill text-white text-center fw-600 bg-danger"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type.split("-")[0]}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {rearRightDoorDefectTypes && (
              <div
                onClick={() =>
                  partId === "offbackdoor_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2 border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "6%",
                  top: "35%",
                  width: "17%",
                  height: "12.5%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {rearRightDoorDefectTypes &&
                    rearRightDoorDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="rounded-pill text-white text-center fw-600 bg-danger"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type.split("-")[0]}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {rearRightFenderDefectTypes && (
              <div
                onClick={() =>
                  partId === "offbackwing_defect" && setOpenList(true)
                }
                className=" border-1 d-flex gap-1 p-2 border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "6%",
                  top: "17%",
                  width: "17%",
                  height: "15%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {rearRightFenderDefectTypes &&
                    rearRightFenderDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="rounded-pill text-white text-center fw-600 bg-danger"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type.split("-")[0]}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {RightFootBandDefectTypes && (
              <div
                onClick={() =>
                  partId === "rightfootboard_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2 border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "1%",
                  top: "37%",
                  width: "6%",
                  height: "30%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {RightFootBandDefectTypes &&
                    RightFootBandDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="rounded-pill text-white text-center fw-600 bg-danger"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type.split("-")[0]}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </Box>
      </Modal>

      {/* logic to open list modal  */}

      <Modal
        open={openList}
        onClose={() => {
          setOpenList(false);
          setPrevImg(null);
          setDefectImage(null);
          setDefectedAreaPic(null);
        }}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="sm-modal position-relative p-3 p-md-4">
          <select
            aria-label="Default select example"
            className="form-select btntextSize"
            onChange={(e) => setDefectedAreaPic(e.target.value)}
          >
            <option value="">Select</option>
            {defectsArr?.map((defect) => (
              <option value={defect.value}>{defect.name}</option>
            ))}
          </select>

          {!prevImg && (
            <Button
              variant="text"
              component="label"
              className="color-white LegendIcon bg p-2"
              fullWidth
              disabled={partId && defectedAreaPic !== "" ? false : true}
            >
              <span className=" me-2 text-capitalize btntextSize">
                Add Image
              </span>{" "}
              <i className="fa-solid fa-plus"></i>
              <input
                name={partId}
                className="form-control visually-hidden"
                id={partId}
                accept="image/**"
                capture="user"
                type="file"
                onChange={(e) => handleDefectImageChange(e, partId)}
              />
            </Button>
          )}
          {prevImg && (
            <div className="position-relative">
              <img
                src={prevImg}
                className=" object-fit-contain w-100 rounded my-3"
                alt="defect image"
              />
              <button
                className="btn btn-danger px-2 py-1 rounded-circle position-absolute"
                style={{ right: -5, top: "7px" }}
                onClick={() => {
                  setPrevImg(null);
                  setDefectImage(null);
                }}
              >
                X
              </button>
            </div>
          )}

          <Button
            className="color-white LegendIcon bg p-2 text-capitalize"
            fullWidth
            disabled={partId && defectedAreaPic !== "" ? false : true}
            onClick={() => {
              defectsTypes();
              setPrevImg(false);
              setOpenList(false);
            }}
          >
            {" "}
            Create{" "}
          </Button>
        </Box>
      </Modal>

      {/* image gallery  */}

      <Modal
        open={openImageGallery}
        onClose={() => setOpenImageGallery(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        {imageLoading ? (
          <>
            <InnerLoader />
          </>
        ) : (
          <Box className="md-modal position-relative p-3 p-md-4 d-flex flex-wrap gap-3 justify-content-around align-items-center">
            {galleryImages &&
              galleryImages?.map((img, ind) => (
                <div className="row">
                  <div
                    key={ind}
                    className="d-flex position-relative border rounded border-secondary-subtle p-2 col-lg-6 col-12 galleryImage"
                  >
                    <img
                      src={`${img.image}`}
                      alt={img.name}
                      className=" object-fit-contain w-100"
                    />
                    <button
                      className="btn btn-danger px-2 py-1 rounded-circle position-absolute"
                      style={{ right: -12, top: -5 }}
                      onClick={() => handleDeleteImage(ind, img.id)}
                    >
                      X
                    </button>
                  </div>
                </div>
              ))}
          </Box>
        )}
      </Modal>

      <div className="container my-5">
        <div className="postInform p-5">
          <form ref={formRef} onSubmit={onSubmit} className="postAdForm">
            <div className="row">
              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_trunkrelease"
                    className="form-label btntextSize text-black "
                  >
                    Trunk Release Lever / Button
                  </label>
                  <div>
                    <select
                      name="ibody_trunkrelease"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.trunkButton}
                      onChange={(e) =>
                        updateCarPart("trunkButton", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Not Applicable"}>Not Applicable</option>
                      <option value={"Working"}>Working</option>
                      <option value={"Not Working"}>Not Working</option>
                    </select>

                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_trunkreleaseimage &&
                        images.ibody_trunkreleaseimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              setImageId("ibody_trunkreleaseimage");
                              handleImageGallery(
                                images.ibody_trunkreleaseimage,
                                "ibody_trunkreleaseimage"
                              );
                              getImages("ibody_trunkreleaseimage");
                            }}
                          >
                            {images.ibody_trunkreleaseimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column mt-2">
                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 color-white bgSecondary shadow-none rounded-pill"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>{" "}
                          {/* <i className="fa-solid fa-plus"></i> */}
                          <input
                            className="form-control visually-hidden"
                            id="ibody_trunkreleaseimage"
                            accept="image/*"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_trunkreleaseimage")
                            }
                          />
                        </Button>
                        <Button
                          variant="contained"
                          component="label"
                          // color="primary"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>{" "}
                          {/* <i className="fa-solid fa-plus"></i> */}
                          <input
                            className="form-control visually-hidden"
                            id="ibody_trunkreleaseimage"
                            accept=".jpg,.jpeg,.png"
                            // capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_trunkreleaseimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_fuelcaprelease"
                    className="form-label btntextSize text-black "
                  >
                    Fuel Cap Release Lever / Button
                  </label>
                  <div>
                    <select
                      name="ibody_fuelcaprelease"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.fuelCapButton}
                      onChange={(e) =>
                        updateCarPart("fuelCapButton", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Not Applicable"}>Not Applicable</option>
                      <option value={"Working"}>Working</option>
                      <option value={"Not Working"}>Not Working</option>
                    </select>

                    <span
                      className="d-flex justify-content-between align-items-center mt-2"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_fuelcapreleaseimage &&
                        images.ibody_fuelcapreleaseimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              setImageId("ibody_fuelcapreleaseimage");
                              handleImageGallery(
                                images.ibody_fuelcapreleaseimage
                              );
                              getImages("ibody_fuelcapreleaseimage");
                            }}
                          >
                            {images.ibody_fuelcapreleaseimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_fuelcapreleaseimage"
                            accept="image/*"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_fuelcapreleaseimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          // color="primary"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>{" "}
                          {/* <i className="fa-solid fa-plus"></i> */}
                          <input
                            className="form-control visually-hidden"
                            id="ibody_fuelcapreleaseimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_fuelcapreleaseimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_bonetrelease"
                    className="form-label btntextSize text-black "
                  >
                    Bonnet Release Lever / Button
                  </label>
                  <div>
                    <select
                      name="ibody_bonetrelease"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.bonnetButton}
                      onChange={(e) =>
                        updateCarPart("bonnetButton", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Not Applicable"}>Not Applicable</option>
                      <option value={"Working"}>Working</option>
                      <option value={"Not Working"}>Not Working</option>
                    </select>

                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_bonnetlreleaseimage &&
                        images.ibody_bonnetlreleaseimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_bonnetlreleaseimage
                              );
                              setImageId("ibody_bonnetlreleaseimage");
                              getImages("ibody_bonnetlreleaseimage");
                            }}
                          >
                            {images.ibody_bonnetlreleaseimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_bonnetlreleaseimage"
                            accept="/image**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_bonnetlreleaseimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_bonnetlreleaseimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_bonnetlreleaseimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_engineoillevel"
                    className="form-label btntextSize text-black "
                  >
                    Engine Oil Level
                  </label>
                  <div>
                    <select
                      name="ibody_engineoillevel"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.engineOilLevel}
                      onChange={(e) =>
                        updateCarPart("engineOilLevel", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Not Applicable"}>Not Applicable</option>
                      <option value={"Complete and Clean"}>
                        Complete and Clean
                      </option>
                      <option value={"Black"}>Black</option>
                      <option value={"Incomplete"}>Incomplete</option>
                      <option value={"Sludge"}>Sludge</option>
                    </select>

                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_engineoillevelimage &&
                        images.ibody_engineoillevelimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_engineoillevelimage
                              );
                              setImageId("ibody_engineoillevelimage");
                              getImages("ibody_engineoillevelimage");
                            }}
                          >
                            {images.ibody_engineoillevelimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_engineoillevelimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_engineoillevelimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_engineoillevelimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_engineoillevelimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_wires"
                    className="form-label btntextSize text-black "
                  >
                    Wires (Wiring Harness)
                  </label>
                  <div>
                    <select
                      name="ibody_wires"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.wiringHarness}
                      onChange={(e) =>
                        updateCarPart("wiringHarness", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Ok"}>Ok</option>
                      <option value={"Repaired"}>Repaired</option>
                      <option value={"Damaged"}>Damaged</option>
                    </select>

                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_wiringharnessimage &&
                        images.ibody_wiringharnessimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_wiringharnessimage
                              );
                              setImageId("ibody_wiringharnessimage");
                              getImages("ibody_wiringharnessimage");
                            }}
                          >
                            {images.ibody_wiringharnessimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_wiringharnessimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_wiringharnessimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_wiringharnessimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_wiringharnessimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_hoses"
                    className="form-label btntextSize text-black "
                  >
                    Hoses
                  </label>
                  <div>
                    <select
                      name="ibody_hoses"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.hoses}
                      onChange={(e) => updateCarPart("hoses", e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value={"Ok"}>Ok</option>
                      <option value={"Repaired"}>Repaired</option>
                      <option value={"Damaged"}>Damaged</option>
                    </select>

                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_hosesimage &&
                        images.ibody_hosesimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(images.ibody_hosesimage);
                              setImageId("ibody_hosesimage");
                              getImages("ibody_hosesimage");
                            }}
                          >
                            {images.ibody_hosesimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_hosesimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_hosesimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_hosesimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_hosesimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_fanbelts"
                    className="form-label btntextSize text-black "
                  >
                    Belts (Fan)
                  </label>
                  <div>
                    <select
                      name="ibody_fanbelts"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.beltsFan}
                      onChange={(e) =>
                        updateCarPart("beltsFan", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Not Applicable"}>Not Applicable</option>
                      <option value={"Ok"}>Ok</option>
                      <option value={"Not Ok"}>Not Ok</option>
                    </select>

                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_beltsfanimage &&
                        images.ibody_beltsfanimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(images.ibody_beltsfanimage);
                              setImageId("ibody_beltsfanimage");
                              getImages("ibody_beltsfanimage");
                            }}
                          >
                            {images.ibody_beltsfanimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_beltsfanimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_beltsfanimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_beltsfanimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_beltsfanimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_acbelts"
                    className="form-label btntextSize text-black "
                  >
                    Belts (Ac)
                  </label>
                  <div>
                    <select
                      name="ibody_acbelts"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.beltsAc}
                      onChange={(e) => updateCarPart("beltsAc", e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value={"Not Applicable"}>Not Applicable</option>
                      <option value={"Ok"}>Ok</option>
                      <option value={"Not Ok"}>Not Ok</option>
                    </select>

                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_beltsacimage &&
                        images.ibody_beltsacimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(images.ibody_beltsacimage);
                              setImageId("ibody_beltsacimage");
                              getImages("ibody_beltsacimage");
                            }}
                          >
                            {images.ibody_beltsacimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_beltsacimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_beltsacimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_beltsacimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_beltsacimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_radiator"
                    className="form-label btntextSize text-black "
                  >
                    Radiator
                  </label>
                  <div>
                    <select
                      name="ibody_radiator"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.radiator}
                      onChange={(e) =>
                        updateCarPart("radiator", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Ok"}>Ok</option>
                      <option value={"Fines Damaged"}>Fines Damaged</option>
                      <option value={"Rusted"}>Rusted</option>
                      <option value={"Leakage"}>Leakage</option>
                    </select>
                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_radiatorimage &&
                        images.ibody_radiatorimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(images.ibody_radiatorimage);
                              setImageId("ibody_radiatorimage");
                              getImages("ibody_radiatorimage");
                            }}
                          >
                            {images.ibody_radiatorimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_radiatorimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_radiatorimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_radiatorimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_radiatorimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_terminalscondition"
                    className="form-label btntextSize text-black "
                  >
                    Terminals Condition
                  </label>
                  <div>
                    <select
                      name="ibody_terminalscondition"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.terminalsCondition}
                      onChange={(e) =>
                        updateCarPart("terminalsCondition", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Ok"}>Ok</option>
                      <option value={"Rusted"}>Rusted</option>
                      <option value={"Broken"}>Broken</option>
                    </select>
                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_terminalsconditionimage &&
                        images.ibody_terminalsconditionimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_terminalsconditionimage
                              );
                              setImageId("ibody_terminalsconditionimage");
                              getImages("ibody_terminalsconditionimage");
                            }}
                          >
                            {images.ibody_terminalsconditionimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_terminalsconditionimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "ibody_terminalsconditionimage"
                              )
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_terminalsconditionimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "ibody_terminalsconditionimage"
                              )
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_voltage"
                    className="form-label btntextSize text-black "
                  >
                    Voltage
                  </label>
                  <div>
                    <input
                      name="ibody_voltage"
                      className="form-control btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.voltage}
                      onChange={(e) => updateCarPart("voltage", e.target.value)}
                    />

                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_voltageimage &&
                        images.ibody_voltageimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(images.ibody_voltageimage);
                              setImageId("ibody_voltageimage");
                              getImages("ibody_voltageimage");
                            }}
                          >
                            {images.ibody_voltageimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_voltageimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_voltageimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_voltageimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_voltageimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_rrightstruttower"
                    className="form-label btntextSize text-black "
                  >
                    Right Strut Tower Apron
                  </label>
                  <div>
                    <select
                      name="ibody_rrightstruttower"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.rightStrutPowerApon}
                      onChange={(e) =>
                        updateCarPart("rightStrutPowerApon", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Non Accidented"}>Non Accidented</option>
                      <option value={"Accidented"}>Accidented</option>
                    </select>
                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_rightstrutpoweraponimage &&
                        images.ibody_rightstrutpoweraponimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_rightstrutpoweraponimage
                              );
                              setImageId("ibody_rightstrutpoweraponimage");
                              getImages("ibody_rightstrutpoweraponimage");
                            }}
                          >
                            {images.ibody_rightstrutpoweraponimage.length}{" "}
                            photos <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_rightstrutpoweraponimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "ibody_rightstrutpoweraponimage"
                              )
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_rightstrutpoweraponimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "ibody_rightstrutpoweraponimage"
                              )
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_rightfrontrail"
                    className="form-label btntextSize text-black "
                  >
                    Right Front Rail
                  </label>
                  <div>
                    <select
                      name="ibody_rightfrontrail"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.rightFrontRail}
                      onChange={(e) =>
                        updateCarPart("rightFrontRail", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Not Applicable"}>Not Applicable</option>
                      <option value={"Non Accidented"}>Non Accidented</option>
                      <option value={"Accidented"}>Accidented</option>
                    </select>
                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_rightfrontrailimage &&
                        images.ibody_rightfrontrailimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_rightfrontrailimage
                              );
                              setImageId("ibody_rightfrontrailimage");
                              getImages("ibody_rightfrontrailimage");
                            }}
                          >
                            {images.ibody_rightfrontrailimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_rightfrontrailimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_rightfrontrailimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_rightfrontrailimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_rightfrontrailimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_leftstruttower"
                    className="form-label btntextSize text-black "
                  >
                    Left Strut Tower Apron
                  </label>
                  <div>
                    <select
                      name="ibody_leftstruttower"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.leftStrutPowerApon}
                      onChange={(e) =>
                        updateCarPart("leftStrutPowerApon", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Non Accidented"}>Non Accidented</option>
                      <option value={"Accidented"}>Accidented</option>
                    </select>
                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_leftstrutpoweraponimage &&
                        images.ibody_leftstrutpoweraponimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_leftstrutpoweraponimage
                              );
                              setImageId("ibody_leftstrutpoweraponimage");
                              getImages("ibody_leftstrutpoweraponimage");
                            }}
                          >
                            {images.ibody_leftstrutpoweraponimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_leftstrutpoweraponimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "ibody_leftstrutpoweraponimage"
                              )
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_leftstrutpoweraponimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "ibody_leftstrutpoweraponimage"
                              )
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_rleftfrontrail"
                    className="form-label btntextSize text-black "
                  >
                    Left Front Rail
                  </label>
                  <div>
                    <select
                      name="ibody_rleftfrontrail"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.leftFrontRail}
                      onChange={(e) =>
                        updateCarPart("leftFrontRail", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Not Applicable"}>Not Applicable</option>
                      <option value={"Non Accidented"}>Non Accidented</option>
                      <option value={"Accidented"}>Accidented</option>
                    </select>
                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_leftfrontrailimage &&
                        images.ibody_leftfrontrailimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_leftfrontrailimage
                              );
                              setImageId("ibody_leftfrontrailimage");
                              getImages("ibody_leftfrontrailimage");
                            }}
                          >
                            {images.ibody_leftfrontrailimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_leftfrontrailimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_leftfrontrailimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_leftfrontrailimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_leftfrontrailimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_rediatorcoresupport"
                    className="form-label btntextSize text-black "
                  >
                    Radiator Core Support
                  </label>
                  <div>
                    <select
                      name="ibody_rediatorcoresupport"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.radiatorCoreSupport}
                      onChange={(e) =>
                        updateCarPart("radiatorCoreSupport", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Non Accidented"}>Non Accidented</option>
                      <option value={"Accidented"}>Accidented</option>
                    </select>
                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_radiatorcoresupportimage &&
                        images.ibody_radiatorcoresupportimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_radiatorcoresupportimage
                              );
                              setImageId("ibody_radiatorcoresupportimage");
                              getImages("ibody_radiatorcoresupportimage");
                            }}
                          >
                            {images.ibody_radiatorcoresupportimage.length}{" "}
                            photos <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_radiatorcoresupportimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "ibody_radiatorcoresupportimage"
                              )
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_radiatorcoresupportimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "ibody_radiatorcoresupportimage"
                              )
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_cowlpanelfirewall"
                    className="form-label btntextSize text-black "
                  >
                    Cowl Panel Firewall
                  </label>
                  <div>
                    <select
                      name="ibody_cowlpanelfirewall"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.cowlPanelFirewall}
                      onChange={(e) =>
                        updateCarPart("cowlPanelFirewall", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Non Accidented"}>Non Accidented</option>
                      <option value={"Accidented"}>Accidented</option>
                    </select>
                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_cowlpanelfirewallimage &&
                        images.ibody_cowlpanelfirewallimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_cowlpanelfirewallimage
                              );
                              setImageId("ibody_cowlpanelfirewallimage");
                              getImages("ibody_cowlpanelfirewallimage");
                            }}
                          >
                            {images.ibody_cowlpanelfirewallimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_cowlpanelfirewallimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "ibody_cowlpanelfirewallimage"
                              )
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_cowlpanelfirewallimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "ibody_cowlpanelfirewallimage"
                              )
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_frontsubframe"
                    className="form-label btntextSize text-black "
                  >
                    Front Sub Frame
                  </label>
                  <div>
                    <select
                      name="ibody_frontsubframe"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.frontSubFrame}
                      onChange={(e) =>
                        updateCarPart("frontSubFrame", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Not Applicable"}>Not Applicable</option>
                      <option value={"Non Accidented"}>Non Accidented</option>
                      <option value={"Accidented"}>Accidented</option>
                    </select>
                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_frontsubframeimage &&
                        images.ibody_frontsubframeimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_frontsubframeimage
                              );
                              setImageId("ibody_frontsubframeimage");
                              getImages("ibody_frontsubframeimage");
                            }}
                          >
                            {images.ibody_frontsubframeimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_frontsubframeimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_frontsubframeimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_frontsubframeimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_frontsubframeimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              {/* front bumper */}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    htmlFor="ibody_frontbumper"
                    className="form-label btntextSize text-black "
                  >
                    Front Bumper
                  </label>
                  <div>
                    <select
                      name="ibody_frontbumper"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.frontBumper}
                      onChange={(e) => {
                        updateCarPart("frontBumper", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setFrontBumperDefectBtn(true);
                        } else {
                          setFrontBumperDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value={"Ok"}>Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {frontBumperDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.frontBumper === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("frontBumper_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}

                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_frontbumperimage &&
                        images.ibody_frontbumperimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(images.ibody_frontbumperimage);
                              setImageId("ibody_frontbumperimage");
                              getImages("ibody_frontbumperimage");
                            }}
                          >
                            {images.ibody_frontbumperimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_frontbumperimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_frontbumperimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_frontbumperimage"
                            accept=".jpg,.jpeg,.png"
                            // capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_frontbumperimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              {/* bonnet  */}
              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_bonnet"
                    className="form-label btntextSize text-black "
                  >
                    Bonnet
                  </label>
                  <div>
                    <select
                      name="ibody_bonnet"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.bonnet}
                      onChange={(e) => {
                        updateCarPart("bonnet", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setBonnetDefectBtn(true);
                        } else {
                          setBonnetDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">OK</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {bonnetDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.bonnet === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("bonnet_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}

                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_bonnetimage &&
                        images.ibody_bonnetimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(images.ibody_bonnetimage);
                              setImageId("ibody_bonnetimage");
                              getImages("ibody_bonnetimage");
                            }}
                          >
                            {images.ibody_bonnetimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}{" "}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_bonnetimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_bonnetimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_bonnetimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_bonnetimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_leftheadlight"
                    className="form-label btntextSize text-black "
                  >
                    Left Headlight (Condition)
                  </label>
                  <div>
                    <select
                      name="ibody_leftheadlight"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.leftHeadlightCondition}
                      onChange={(e) =>
                        updateCarPart("leftHeadlightCondition", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Perfect"}>Perfect</option>
                      <option value={"Scratches"}>Scratches</option>
                      <option value={"Foggy"}>Foggy</option>
                      <option value={"Repaired"}>Repaired</option>
                      <option value={"Cracked or Broken"}>
                        Cracked or Broken
                      </option>
                    </select>
                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_leftheadlightimage &&
                        images.ibody_leftheadlightimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_leftheadlightimage
                              );
                              setImageId("ibody_leftheadlightimage");
                              getImages("ibody_leftheadlightimage");
                            }}
                          >
                            {images.ibody_leftheadlightimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_leftheadlightimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_leftheadlightimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_leftheadlightimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_leftheadlightimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_rightheadlight"
                    className="form-label btntextSize text-black "
                  >
                    Right Headlight (Condition)
                  </label>
                  <div>
                    <select
                      name="ibody_rightheadlight"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.rightHeadlightCondition}
                      onChange={(e) =>
                        updateCarPart("rightHeadlightCondition", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Perfect"}>Perfect</option>
                      <option value={"Scratches"}>Scratches</option>
                      <option value={"Foggy"}>Foggy</option>
                      <option value={"Repaired"}>Repaired</option>
                      <option value={"Cracked or Broken"}>
                        Cracked or Broken
                      </option>
                    </select>
                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_rightheadlightimage &&
                        images.ibody_rightheadlightimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_rightheadlightimage
                              );
                              setImageId("ibody_rightheadlightimage");
                              getImages("ibody_rightheadlightimage");
                            }}
                          >
                            {images.ibody_rightheadlightimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_rightheadlightimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_rightheadlightimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_rightheadlightimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_rightheadlightimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              {/* front wind sheild  */}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_frontwindshield"
                    className="form-label btntextSize text-black "
                  >
                    Front WindShield (Condition)
                  </label>
                  <div>
                    <select
                      name="ibody_frontwindshield"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.frontWindShield}
                      onChange={(e) =>
                        updateCarPart("frontWindShield", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Ok"}>Ok</option>
                      <option value="Scratches">Scratches</option>
                      <option value="Chip">Chip</option>
                      <option value="Crack">Crack</option>
                    </select>

                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_frontwindshieldimage &&
                        images.ibody_frontwindshieldimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_frontwindshieldimage
                              );
                              setImageId("ibody_frontwindshieldimage");
                              getImages("ibody_frontwindshieldimage");
                            }}
                          >
                            {images.ibody_frontwindshieldimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_frontwindshieldimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_frontwindshieldimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_frontwindshieldimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_frontwindshieldimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              {/* right front fender */}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_frontrightfender"
                    className="form-label btntextSize text-black "
                  >
                    Front Right Fender
                  </label>
                  <div>
                    <select
                      name="ibody_frontrightfender"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.frontRightFender}
                      onChange={(e) => {
                        updateCarPart("frontRightFender", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setFrontRightFenderDefectBtn(true);
                        } else {
                          setFrontRightFenderDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value={"Ok"}>Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {frontRightFenderDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.frontRightFender === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("offfrontwing_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}

                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_offfrontwingimage &&
                        images.ibody_offfrontwingimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_offfrontwingimage
                              );
                              setImageId("ibody_offfrontwingimage");
                              getImages("ibody_offfrontwingimage");
                            }}
                          >
                            {images.ibody_offfrontwingimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_offfrontwingimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_offfrontwingimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_offfrontwingimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_offfrontwingimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              {/* right a pillar  */}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_rightapillar"
                    className="form-label btntextSize text-black "
                  >
                    Right A Pillar
                  </label>
                  <div>
                    <select
                      name="ibody_rightapillar"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.rightAPillar}
                      onChange={(e) =>
                        updateCarPart("rightAPillar", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Accidented"}>Accidented</option>
                      <option value="Non Accidented">Non Accidented</option>
                    </select>
                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_rightAPillarimage &&
                        images.ibody_rightAPillarimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_rightAPillarimage
                              );
                              setImageId("ibody_rightAPillarimage");
                              getImages("ibody_rightAPillarimage");
                            }}
                          >
                            {images.ibody_rightAPillarimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_rightAPillarimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_rightAPillarimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_rightAPillarimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_rightAPillarimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              {/* front right door  */}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_frontrightdoor"
                    className="form-label btntextSize text-black "
                  >
                    Front Right Door
                  </label>
                  <div>
                    <select
                      name="ibody_frontrightdoor"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.frontRightDoor}
                      onChange={(e) => {
                        updateCarPart("frontRightDoor", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setFrontRightDoorDefectBtn(true);
                        } else {
                          setFrontRightDoorDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value={"Ok"}>Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {frontRightDoorDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.frontRightDoor === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("offfrontdoor_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}

                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_offfrontdoorimage &&
                        images.ibody_offfrontdoorimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_offfrontdoorimage
                              );
                              setImageId("ibody_offfrontdoorimage");
                              getImages("ibody_offfrontdoorimage");
                            }}
                          >
                            {images.ibody_offfrontdoorimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_offfrontdoorimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_offfrontdoorimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_offfrontdoorimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_offfrontdoorimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              {/* right b pillar  */}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_frontbpillar"
                    className="form-label btntextSize text-black "
                  >
                    Right B Pillar
                  </label>
                  <div>
                    <select
                      name="ibody_frontbpillar"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.frontRightBPillar}
                      onChange={(e) =>
                        updateCarPart("frontRightBPillar", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Accidented"}>Accidented</option>
                      <option value="Non Accidented">Non Accidented</option>
                    </select>
                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_rightbpillarimage &&
                        images.ibody_rightbpillarimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_rightbpillarimage
                              );
                              setImageId("ibody_rightbpillarimage");
                              getImages("ibody_rightbpillarimage");
                            }}
                          >
                            {images.ibody_rightbpillarimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_rightbpillarimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_rightbpillarimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_rightbpillarimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_rightbpillarimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_rightsidemirro"
                    className="form-label btntextSize text-black "
                  >
                    Right Side Mirror
                  </label>
                  <div>
                    <select
                      name="ibody_rightsidemirro"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.rightMirror}
                      onChange={(e) =>
                        updateCarPart("rightMirror", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Working"}>Working</option>
                      <option value="Broken or Not Rotating">
                        Broken or Not Rotating
                      </option>
                    </select>

                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_rightsidemirrorimage &&
                        images.ibody_rightsidemirrorimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_rightsidemirrorimage
                              );
                              setImageId("ibody_rightsidemirrorimage");
                              getImages("ibody_rightsidemirrorimage");
                            }}
                          >
                            {images.ibody_rightsidemirrorimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_rightsidemirrorimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_rightsidemirrorimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_rightsidemirrorimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_rightsidemirrorimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              {/* rear right door  */}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_rearrightdoor"
                    className="form-label btntextSize text-black "
                  >
                    Rear Right Door
                  </label>
                  <div>
                    <select
                      name="ibody_rearrightdoor"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.rearRightDoor}
                      onChange={(e) => {
                        updateCarPart("rearRightDoor", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setRearRightDoorDefectBtn(true);
                        } else {
                          setRearRightDoorDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value={"Ok"}>Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {rearRightDoorDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.rearRightDoor === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("offbackdoor_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}

                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_offbackdoorimage &&
                        images.ibody_offbackdoorimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(images.ibody_offbackdoorimage);
                              setImageId("ibody_offbackdoorimage");
                              getImages("ibody_offbackdoorimage");
                            }}
                          >
                            {images.ibody_offbackdoorimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_offbackdoorimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_offbackdoorimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_offbackdoorimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_offbackdoorimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              {/* right c clip  */}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_rightcpillar"
                    className="form-label btntextSize text-black "
                  >
                    Right C Pillar
                  </label>
                  <div>
                    <select
                      name="ibody_rightcpillar"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.rearRightCPillar}
                      onChange={(e) =>
                        updateCarPart("rearRightCPillar", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value={"Not Applicable"}>Not Applicable</option>
                      <option value={"Non Accidented"}>Non Accidented</option>
                      <option value={"Accidented"}>Accidented</option>
                    </select>
                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_rightcpillarimage &&
                        images.ibody_rightcpillarimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_rightcpillarimage
                              );
                              setImageId("ibody_rightcpillarimage");
                              getImages("ibody_rightcpillarimage");
                            }}
                          >
                            {images.ibody_rightcpillarimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_rightcpillarimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_rightcpillarimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_rightcpillarimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_rightcpillarimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              {/* right footband  */}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_rightfootboard"
                    className="form-label btntextSize text-black "
                  >
                    Right Foot board
                  </label>
                  <div>
                    <select
                      name="ibody_rightfootboard"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.rightFootBand}
                      onChange={(e) => {
                        updateCarPart("rightFootBand", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setRightFootBandDefectBtn(true);
                        } else {
                          setRightFootBandDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value={"Ok"}>Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {rightFootBandDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.rightFootBand === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("rightfootboard_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}

                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_rightfootbandimage &&
                        images.ibody_rightfootbandimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_rightfootbandimage
                              );
                              setImageId("ibody_rightfootbandimage");
                              getImages("ibody_rightfootbandimage");
                            }}
                          >
                            {images.ibody_rightfootbandimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_rightfootbandimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_rightfootbandimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_rightfootbandimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_rightfootbandimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              {/* rear right fender  */}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_rearrightfender"
                    className="form-label btntextSize text-black "
                  >
                    Rear Right Fender
                  </label>
                  <div>
                    <select
                      name="ibody_rearrightfender"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.rearRightFender}
                      onChange={(e) => {
                        updateCarPart("rearRightFender", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setRearRightFenderDefectBtn(true);
                        } else {
                          setRearRightFenderDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value={"Ok"}>Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {rearRightFenderDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.rearRightFender === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("offbackwing_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}

                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_offbackwingimage &&
                        images.ibody_offbackwingimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(images.ibody_offbackwingimage);
                              setImageId("ibody_offbackwingimage");
                              getImages("ibody_offbackwingimage");
                            }}
                          >
                            {images.ibody_offbackwingimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_offbackwingimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_offbackwingimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_offbackwingimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_offbackwingimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              {/* right d pillar  */}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_rightbdpillar"
                    className="form-label btntextSize text-black "
                  >
                    Right D Pillar
                  </label>
                  <div>
                    <select
                      name="ibody_rightbdpillar"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.rearRightDPillar}
                      onChange={(e) =>
                        updateCarPart("rearRightDPillar", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Non-Accidented">Non-Accidented</option>
                      <option value="Accidented">Accidented</option>
                    </select>
                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_rightdpillarimage &&
                        images.ibody_rightdpillarimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_rightdpillarimage
                              );
                              setImageId("ibody_rightdpillarimage");
                              getImages("ibody_rightdpillarimage");
                            }}
                          >
                            {images.ibody_rightdpillarimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_rightdpillarimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_rightdpillarimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_rightdpillarimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_rightdpillarimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              {/* right taillight  */}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ibody_righttaillight"
                    className="form-label btntextSize text-black "
                  >
                    Right Taillight (Condition )
                  </label>
                  <div>
                    <select
                      name="ibody_righttaillight"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.rightTailLightCondition}
                      onChange={(e) =>
                        updateCarPart("rightTailLightCondition", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Perfect">Perfect</option>
                      <option value="Scratches">Scratches</option>
                      <option value="Foggy">Foggy</option>
                      <option value="Repaired">Repaired</option>
                      <option value="Cracked or Broken">
                        Cracked or Broken
                      </option>
                    </select>
                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    >
                      <span>
                        {images?.ibody_righttaillightimage &&
                        images.ibody_righttaillightimage.length > 0 ? (
                          <span
                            className="color-secondary fw-600 p-0"
                            onClick={() => {
                              handleImageGallery(
                                images.ibody_righttaillightimage
                              );
                              setImageId("ibody_righttaillightimage");
                              getImages("ibody_righttaillightimage");
                            }}
                          >
                            {images.ibody_righttaillightimage.length} photos{" "}
                            <ImageIcon color="error" />
                          </span>
                        ) : (
                          <span className="color-secondary fw-600 p-0">
                            0 photos <ImageIcon color="error" />
                          </span>
                        )}
                      </span>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_righttaillightimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_righttaillightimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ibody_righttaillightimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ibody_righttaillightimage")
                            }
                          />
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3">
                <label
                  for="ibody_comment"
                  className="form-label btntextSize text-black "
                >
                  Comment (Optional)
                </label>

                <Form.Control
                  as="textarea"
                  name="ibody_comment"
                  placeholder="Leave a comment here"
                  style={{ height: "130px" }}
                  className="btntextSize"
                />
              </div>

              <div className="text-center pt-2 pb-4 d-flex gap-3 justify-content-center align-items-center">
                <Button
                  type="submit"
                  disabled={pending === true ? true : false}
                  className=" px-5 bgSecondary color-white"
                  variant="contained"
                >
                  Next
                </Button>

                {isSycc && (
                  <Button
                    type="button"
                    disabled={
                      pending === true ||
                      isSubmitting === true ||
                      isSubmittingFields === true
                        ? true
                        : false
                    }
                    className=" px-5 color-white"
                    variant="contained"
                    color="info"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          </form>
          {isModalOpen && (
            <ImageModal
              image={modalImage}
              onClose={handleModalClose}
              open={previewOpen}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Step9;
