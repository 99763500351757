import React, { useRef, useEffect, useState } from "react";
import "../../Styles/style.css";
import Button from "@mui/material/Button";
import ImageModal from "../imagePreviewModal";
import axios from "axios";
import LoadingModal from "../../modals/loading-modal";
import { Alert, Box, Modal, Snackbar } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import baseUrl from "../../../api/ipconfig";
import { Form } from "react-bootstrap";
import InspectionSuccess from "../../modals/inspection-success";
import { useHistory } from "react-router-dom";
import InnerLoader from "../../loader/innerLoader";
import imageCompression from "browser-image-compression";
import s3 from "../../../config/s3Config";

const Step2 = ({ onSuccess, previouse, userData }) => {
  const token = localStorage.getItem("token");

  const history = useHistory();
  const [imagePart, setImagePart] = useState(null);

  const [pending, setPending] = useState(false);
  const [pendingData, setPendingData] = useState("");
  const [errOpen, setErrOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [payment, setPayment] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [openPayment, setOpenPayment] = useState(false);
  const [securityDeposit, setsSecurityDeposit] = useState(null);
  const [paymentToken, setsPaymentToken] = useState("");
  const [paymentStatus, setsPaymentStatus] = useState("");

  const [uploadingImages, setUploadingImages] = useState([]);
  const [uploadingImagesIndex, setUploadingImagesIndex] = useState([]);

  const formRef = useRef(null);
  const btnRef = useRef(null);
  const [successIsOpen, setSuccessIsOpen] = React.useState(false);

  const [images, setImages] = React.useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalImage, setModalImage] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [imagePath, setImagePath] = useState(null);

  const [openImageGallery, setOpenImageGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [imageID, setImageId] = useState(null);

  const LoginClose = () => {
    setSuccessIsOpen(false);
  };

  useEffect(() => {
    if (galleryImages.length === 0) {
      setOpenImageGallery(false);
    }
  }, [galleryImages.length]);

  const extractTokenFromUrl = (url, paramName) => {
    const urlSearchParams = new URLSearchParams(url);
    return urlSearchParams.get(paramName);
  };

  const getImages = async (fieldName) => {
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");

    setImageLoading(true);

    try {
      const response = await axios.get(`${baseUrl}/getinspectionimage`, {
        params: {
          iinitial_token: StepToken,
          image_name: fieldName,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setImages((prevImages) => ({
        ...prevImages,
        [fieldName]: [...response.data?.images],
      }));

      setGalleryImages(response?.data?.images);

      setImagePath(response?.data?.imagepath);

      // const updatedImages = uploadingImages.filter(item => item !== response?.data?.placement);

      //   // Update the state with the new array
      //   setUploadingImages(updatedImages);
    } catch (error) {
      //console.log(error);
    } finally {
      setImageLoading(false);
      // const updatedImages = uploadingImages.filter(item => item !== fieldName);

      //   // Update the state with the new array
      // setUploadingImages(uploadingImages);
    }
  };

  const handleImageChange = async (e, fieldName) => {
    const files = Array.from(e.target.files);
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");

    if (files.length > 0) {
      try {
        // Set pending status
        setUploadingImages([...uploadingImages, fieldName]);
        setUploadingImagesIndex([...uploadingImagesIndex, fieldName]);
        setPendingData("Image uploading");

        const uploadPromises = files.map(async (file) => {
          const options = {
            maxSizeMB: 0.06,
            maxWidthOrHeight: 800,
            useWebWorker: true,
          };

          const compressedFile = await imageCompression(file, options);

          const compressedImageFile = new File([compressedFile], file.name, {
            type: file.type,
          });

          // Dynamic folder creation
          // const folderName = StepToken;
          // const childFolderName = fieldName;
          const s3Key = `public/inspection/${StepToken}/${fieldName}/${compressedImageFile.name}`;

          const params = {
            Bucket: "famewheels-pvt",
            Key: s3Key,
            Body: compressedImageFile,
            // ACL: 'public-read', // Or the appropriate ACL
            ContentType: compressedImageFile.type,
          };

          const response = await s3.upload(params).promise();

          const url = response.Key;

          const ImageName = url.substring(url.lastIndexOf("/") + 1);

          const formData = new FormData();
          formData.append("image_name", fieldName);
          formData.append("iinitial_token", StepToken);
          formData.append("inspectionstep_id", 7);
          formData.append(`file`, ImageName);

          const responseApi = await axios.post(
            `${baseUrl}/saveinspectionimage`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          // Call getImages for each uploaded image (assuming getImages updates the UI)
          await getImages(fieldName);

          // Update the uploadingImages state after successful upload
          setUploadingImages((prevUploadImages) =>
            prevUploadImages.filter((item) => item !== fieldName)
          );
          setUploadingImagesIndex((prevUploadImages) =>
            prevUploadImages.filter((item) => item !== fieldName)
          );

          return response;
        });

        // Wait for all uploadPromises to resolve
        await Promise.all(uploadPromises);

        // Show success message
        setSuccessOpen(true);
        setSuccess("Images uploaded successfully");
      } catch (error) {
        console.error("Error uploading images:", error);
        setErrOpen(true);
        setError("Couldn't upload image, try again");
        setUploadingImages([]);
        setUploadingImagesIndex([]);
      } finally {
        // Reset pending status
        setPending(false);
      }
    }
  };

  const handleDeleteImage = async (ind, id) => {
    try {
      setPending(true);
      setPendingData("Deleting Image");
      const response = await axios.delete(`${baseUrl}/deleteinspection`, {
        params: {
          image_id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response?.status === 200) {
        const updatedGalleryImages = [...galleryImages];
        updatedGalleryImages.splice(ind, 1);
        setGalleryImages(updatedGalleryImages);

        if (images[imageID]) {
          const updatedImages = [...images[imageID]];
          updatedImages.splice(ind, 1);
          setImages({ ...images, [imageID]: updatedImages });
        }
      }
      setSuccessOpen(true);
      setSuccess("Image Deleted Successfully");
    } catch (error) {
      //console.log("delet image error", error);
      setErrOpen(true);
      setError("Couldn't delete image");
    } finally {
      setPending(false);
    }
  };

  const handleImageGallery = (images) => {
    setOpenImageGallery(true);
    setGalleryImages(images);
  };

  useEffect(() => {
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    const formData = new FormData();

    const fetchDraftPending = async () => {
      formData.append("iinitial_token", StepToken);
      formData.append("inspectionstep_id", 7);

      try {
        const response = await axios.post(
          `${baseUrl}/inspectionstepdetails`,
          formData,
          {
            // params: {
            //   iinitial_token: StepToken,
            //   inspectionstep_id: 3,
            // },
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        //console.log(response?.data?.initial, "draft step interior");
        const formData2 = response?.data?.initial;
        populateFormWithResponse(formData2);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDraftPending();
  }, [token]);

  const populateFormWithResponse = (formData) => {
    if (!formData || typeof formData !== "object") {
      console.error("Invalid formData:", formData);
      return;
    }

    const formElements = formRef.current.elements;

    Object.keys(formData).forEach((fieldName) => {
      const field = formElements[fieldName];

      if (field) {
        field.value = formData[fieldName];
      }
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const form = formRef.current;
      if (form) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formRef]);

  const handleModalOpen = (image) => {
    setIsModalOpen(true);
    setModalImage(image);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  const previewOpen = (image) => {
    setIsModalOpen(true);
    setModalImage(image);
  };

  const dataURLtoBlob = (dataURL) => {
    const parts = dataURL.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const arr = new Uint8Array(raw.length);

    for (let i = 0; i < raw.length; i++) {
      arr[i] = raw.charCodeAt(i);
    }

    return new Blob([arr], { type: contentType });
  };

  //   const url_id = window.location.search;
  //   const inspectionId = extractTokenFromUrl(url_id, "inspection");

  // useEffect(()=>{
  //   const getInpectionId = async ()=>{
  //     try {
  //       const response = await axios.get(`${baseUrl}/inspectionrequestdetail`,{
  //         params:{
  //           inspection_id:inspectionId
  //         }
  //       })

  //       setsPaymentStatus(response?.data?.vehicledetail?.payment_status)

  //     } catch (error) {
  //       console.error(
  //         error
  //       )
  //     }
  //   }

  //   getInpectionId()

  // },[inspectionId])

  const url = window.location.search;
  const StepToken = extractTokenFromUrl(url, "token");

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      const formId = extractTokenFromUrl(url, "form_id");

      const CreateUser = async () => {
        // const imageFile = dataURLtoBlob(images.ibody_rediatorcoreimage);
        if (formRef.current) {
          const params = {
            data: new FormData(formRef.current),
          };

          params.data.append("userId", userData?.id);
          params.data.append(
            "iinitial_id",
            formId ? formId : previouse?.iinitial_id
          );
          params.data.append("iinitial_token", StepToken);
          params.data.append("is_edit", 0);

          // params.data.append("imageFile", imageFile);
          // Object.entries(images).forEach(([fieldName, file]) => {
          //   params.data.append(fieldName, dataURLtoBlob(file));
          // });

          const response = await axios.post(
            `${baseUrl}/finishinspection`,
            params?.data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          //console.log("====================================");
          //console.log(response);
          //console.log("====================================");
          setIsSubmitting(false);

          // if (paymentStatus !== "paid") {

          //   setOpenPayment(true)

          // }

          formRef.current.hasUnsavedChanges = false;
        }
        onSuccess();

        // setTimeout(() => {
        // setSuccessIsOpen(false);

        // }, 1000);
      };

      if (formRef.current) {
        btnRef.current?.setLoader(true);
        try {
          setPending(true);
          setPendingData("Completing Step 7");
          CreateUser();
        } catch (e) {
          console.error(e);
          setErrOpen(true);
          setError(`Step 7 Submit Failed!`);
          btnRef.current?.setLoader(false);
          setIsSubmitting(false);
        } finally {
          setPending(false);
          setIsSubmitting(false);
        }
        btnRef.current?.setLoader(false);
      }
    },
    [onSuccess, previouse?.iinitial_token, token, userData?.id]
  );

  const getImageByValue = (value) => {
    // Find the item with the specified value
    const item = uploadingImages.find((item) => item === value);

    // Return the found item
    return item;
  };

  const hidePendingImage = (imageName) => {
    const updatedImages = uploadingImagesIndex.filter(
      (item) => item !== imageName
    );
    // Update the state with the new array
    setUploadingImagesIndex(updatedImages);
  };

  // const handlePaymentSubmit = async (e)=>{
  // e.preventDefault()

  // const formData = new FormData()

  // formData.append("payment_status",payment)
  // formData.append("payment_method",paymentMethod)
  // formData.append("security_deposit",securityDeposit)
  // formData.append("payment_token",paymentToken)
  // formData.append("inspection_id",inspectionId)

  // try {

  // const response = await axios.post(`${baseUrl}/inspection-payment`,formData,{
  //   headers:{
  //     Authorization:`Bearer ${token}`
  //   }
  // })

  // setSuccessIsOpen(true);

  // if (response?.status === 200) {

  //   setTimeout(() => {
  //     setSuccessIsOpen(false);
  //     window.open(`https://inspectionreport.famewheels.com/inspection-report/${StepToken}`);
  //     history.push(`/inspection-history`);
  //   }, 4000);

  // }

  // } catch (error) {
  //   console.log(error)
  // }
  // }

  return (
    <>
      <LoadingModal
        open={isSubmitting}
        onClose={() => setIsSubmitting(false)}
      />

      <Snackbar
        open={errOpen}
        autoHideDuration={4000}
        onClose={() => setErrOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={successOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSuccessOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {success}
        </Alert>
      </Snackbar>

      <Snackbar
        open={pending}
        autoHideDuration={15000}
        onClose={() => setPending(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setPending(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          {pendingData}
        </Alert>
      </Snackbar>

      {uploadingImagesIndex?.map((img, index) => (
        <Snackbar
          key={index}
          open={true}
          // onClose={() => hidePendingImage(img)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          style={{ marginTop: `${index * 60}px` }}
        >
          <Alert
            onClose={() => hidePendingImage(img)}
            severity="info"
            style={{ width: "65%" }}
          >
            {`${pendingData}`}
          </Alert>
        </Snackbar>
      ))}

      <Modal
        open={openImageGallery}
        onClose={() => setOpenImageGallery(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        {imageLoading ? (
          <>
            <InnerLoader />
          </>
        ) : (
          <Box className="md-modal position-relative p-3 p-md-4 d-flex flex-wrap gap-3 justify-content-around align-items-center">
            {galleryImages &&
              galleryImages?.map((img, ind) => (
                <div className="row" key={ind}>
                  <div className="d-flex position-relative border rounded border-secondary-subtle p-2 col-lg-6 col-12 galleryImage">
                    <img
                      src={`${imagePath}/${img.inspectionimage_name}`}
                      alt={img}
                      className="object-fit-contain w-100"
                    />
                    <button
                      className="btn btn-danger px-2 py-1 rounded-circle position-absolute"
                      style={{ right: -12, top: -5 }}
                      onClick={() => handleDeleteImage(ind, img)}
                    >
                      X
                    </button>
                  </div>
                </div>
              ))}
          </Box>
        )}
      </Modal>

      <div className="container my-5">
        <div className="postInform p-5">
          <form ref={formRef} onSubmit={onSubmit} className="postAdForm">
            <div className="row">
              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="frontview"
                  className="form-label btntextSize text-black"
                >
                  Front View Image
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_front_view_image &&
                    images.iex_front_view_image.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_front_view_image);
                          setImageId("iex_front_view_image");
                          getImages("iex_front_view_image");
                        }}
                      >
                        {images.iex_front_view_image.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  {!getImageByValue("iex_front_view_image") && (
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_front_view_image"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_front_view_image")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_front_view_image"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_front_view_image")
                          }
                        />
                      </Button>
                    </div>
                  )}
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="obliquefrontright"
                  className="form-label btntextSize text-black"
                >
                  Oblique Front- Right
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_oblique_front_right &&
                    images.iex_oblique_front_right.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_oblique_front_right);
                          setImageId("iex_oblique_front_right");
                          getImages("iex_oblique_front_right");
                        }}
                      >
                        {images.iex_oblique_front_right.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  {!getImageByValue("iex_oblique_front_right") && (
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_oblique_front_right"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_oblique_front_right")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_oblique_front_right"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_oblique_front_right")
                          }
                        />
                      </Button>
                    </div>
                  )}
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="obliquerearright"
                  className="form-label btntextSize text-black"
                >
                  Oblique Rear- Right
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_oblique_rear_right &&
                    images.iex_oblique_rear_right.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_oblique_rear_right);
                          setImageId("iex_oblique_rear_right");
                          getImages("iex_oblique_rear_right");
                        }}
                      >
                        {images.iex_oblique_rear_right.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  {!getImageByValue("iex_oblique_rear_right") && (
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_oblique_rear_right"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_oblique_rear_right")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_oblique_rear_right"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_oblique_rear_right")
                          }
                        />
                      </Button>
                    </div>
                  )}
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="trunkopenpic"
                  className="form-label btntextSize text-black"
                >
                  Trunk Open
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_trunk_open_pic &&
                    images.iex_trunk_open_pic.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_trunk_open_pic);
                          setImageId("iex_trunk_open_pic");
                          getImages("iex_trunk_open_pic");
                        }}
                      >
                        {images.iex_trunk_open_pic.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  {!getImageByValue("iex_trunk_open_pic") && (
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_trunk_open_pic"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_trunk_open_pic")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_trunk_open_pic"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_trunk_open_pic")
                          }
                        />
                      </Button>
                    </div>
                  )}
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="rearviewimage"
                  className="form-label btntextSize text-black"
                >
                  Rear View Image
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_rear_view_image &&
                    images.iex_rear_view_image.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_rear_view_image);
                          setImageId("iex_rear_view_image");
                          getImages("iex_rear_view_image");
                        }}
                      >
                        {images.iex_rear_view_image.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  {!getImageByValue("iex_rear_view_image") && (
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_rear_view_image"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_rear_view_image")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_rear_view_image"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_rear_view_image")
                          }
                        />
                      </Button>
                    </div>
                  )}
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="obliquerearleft"
                  className="form-label btntextSize text-black"
                >
                  Oblique Rear- Left
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_oblique_rear_left &&
                    images.iex_oblique_rear_left.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_oblique_rear_left);
                          setImageId("iex_oblique_rear_left");
                          getImages("iex_oblique_rear_left");
                        }}
                      >
                        {images.iex_oblique_rear_left.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  {!getImageByValue("iex_oblique_rear_left") && (
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_oblique_rear_left"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_oblique_rear_left")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_oblique_rear_left"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_oblique_rear_left")
                          }
                        />
                      </Button>
                    </div>
                  )}
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="obliquefrontleft"
                  className="form-label btntextSize text-black"
                >
                  Oblique Front- Left
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_oblique_front_left &&
                    images.iex_oblique_front_left.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_oblique_front_left);
                          setImageId("iex_oblique_front_left");
                          getImages("iex_oblique_front_left");
                        }}
                      >
                        {images.iex_oblique_front_left.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  {!getImageByValue("iex_oblique_front_left") && (
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_oblique_front_left"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_oblique_front_left")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_oblique_front_left"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_oblique_front_left")
                          }
                        />
                      </Button>
                    </div>
                  )}
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="vehiclekeys"
                  className="form-label btntextSize text-black"
                >
                  Vehicle Keys Image
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_vehicle_keys &&
                    images.iex_vehicle_keys.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_vehicle_keys);
                          setImageId("iex_vehicle_keys");
                          getImages("iex_vehicle_keys");
                        }}
                      >
                        {images.iex_vehicle_keys.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  {!getImageByValue("iex_vehicle_keys") && (
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_vehicle_keys"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_vehicle_keys")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_vehicle_keys"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_vehicle_keys")
                          }
                        />
                      </Button>
                    </div>
                  )}
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="odometer"
                  className="form-label btntextSize text-black"
                >
                  Odo Meter Image
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_odo_meter &&
                    images.iex_odo_meter.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_odo_meter);
                          setImageId("iex_odo_meter");
                          getImages("iex_odo_meter");
                        }}
                      >
                        {images.iex_odo_meter.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  {!getImageByValue("iex_odo_meter") && (
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_odo_meter"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_odo_meter")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_odo_meter"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_odo_meter")
                          }
                        />
                      </Button>
                    </div>
                  )}
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="enginecompartment"
                  className="form-label btntextSize text-black"
                >
                  Engine Compartment
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_engine_compartment &&
                    images.iex_engine_compartment.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_engine_compartment);
                          setImageId("iex_engine_compartment");
                          getImages("iex_engine_compartment");
                        }}
                      >
                        {images.iex_engine_compartment.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  {!getImageByValue("iex_engine_compartment") && (
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_engine_compartment"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_engine_compartment")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_engine_compartment"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_engine_compartment")
                          }
                        />
                      </Button>
                    </div>
                  )}
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="enginenumber"
                  className="form-label btntextSize text-black"
                >
                  Engine Number
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_engine_number &&
                    images.iex_engine_number.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_engine_number);
                          setImageId("iex_engine_number");
                          getImages("iex_engine_number");
                        }}
                      >
                        {images.iex_engine_number.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  {!getImageByValue("iex_engine_number") && (
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_engine_number"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_engine_number")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_engine_number"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_engine_number")
                          }
                        />
                      </Button>
                    </div>
                  )}
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="chassisnumber"
                  className="form-label btntextSize text-black"
                >
                  Chassis Number
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_chassis_number &&
                    images.iex_chassis_number.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_chassis_number);
                          setImageId("iex_chassis_number");
                          getImages("iex_chassis_number");
                        }}
                      >
                        {images.iex_chassis_number.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  {!getImageByValue("iex_chassis_number") && (
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_chassis_number"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_chassis_number")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_chassis_number"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_chassis_number")
                          }
                        />
                      </Button>
                    </div>
                  )}
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="chassisplate"
                  className="form-label btntextSize text-black"
                >
                  Chassis Plate
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_chassis_plate &&
                    images.iex_chassis_plate.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_chassis_plate);
                          setImageId("iex_chassis_plate");
                          getImages("iex_chassis_plate");
                        }}
                      >
                        {images.iex_chassis_plate.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  {!getImageByValue("iex_chassis_plate") && (
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_chassis_plate"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_chassis_plate")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_chassis_plate"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_chassis_plate")
                          }
                        />
                      </Button>
                    </div>
                  )}
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="frontinterior"
                  className="form-label btntextSize text-black"
                >
                  Front Interior
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_front_interior &&
                    images.iex_front_interior.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_front_interior);
                          setImageId("iex_front_interior");
                          getImages("iex_front_interior");
                        }}
                      >
                        {images.iex_front_interior.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  {!getImageByValue("iex_front_interior") && (
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_front_interior"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_front_interior")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_front_interior"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_front_interior")
                          }
                        />
                      </Button>
                    </div>
                  )}
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="backinterior"
                  className="form-label btntextSize text-black"
                >
                  Back Interior
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_back_interior &&
                    images.iex_back_interior.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_back_interior);
                          setImageId("iex_back_interior");
                          getImages("iex_back_interior");
                        }}
                      >
                        {images.iex_back_interior.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  {!getImageByValue("iex_back_interior") && (
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_back_interior"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_back_interior")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_back_interior"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_back_interior")
                          }
                        />
                      </Button>
                    </div>
                  )}
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="fourway_switch"
                  className="form-label btntextSize text-black"
                >
                  4 Way Switch
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_fourwayswitchimage &&
                    images.iex_fourwayswitchimage.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_fourwayswitchimage);
                          setImageId("iex_fourwayswitchimage");
                          getImages("iex_fourwayswitchimage");
                        }}
                      >
                        {images.iex_fourwayswitchimage.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  {!getImageByValue("iex_fourwayswitchimage") && (
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_fourwayswitchimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_fourwayswitchimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_fourwayswitchimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_fourwayswitchimage")
                          }
                        />
                      </Button>
                    </div>
                  )}
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="extrapictures"
                  className="form-label btntextSize text-black"
                >
                  Extra Pictures
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_extra_images &&
                    images.iex_extra_images.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_extra_images);
                          setImageId("iex_extra_images");
                          getImages("iex_extra_images");
                        }}
                      >
                        {images.iex_extra_images.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  {!getImageByValue("iex_extra_images") && (
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_extra_images"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_extra_images")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iex_extra_images"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iex_extra_images")
                          }
                        />
                      </Button>
                    </div>
                  )}
                </span>
              </div>

              <div className="col-12 mt-3">
                <label
                  for="inspector_comment"
                  className="form-label btntextSize text-black"
                >
                  Comment (Optional)
                </label>

                <Form.Control
                  as="textarea"
                  name="inspector_comment"
                  placeholder="Leave a comment here"
                  style={{ height: "130px" }}
                  className="btntextSize"
                />
              </div>

              <div className="text-center pt-2 pb-4 mt-3">
                <Button
                  type="submit"
                  disabled={pending === true ? true : false}
                  className=" px-5 bgSecondary color-white"
                  variant="contained"
                >
                  Next
                </Button>
              </div>
            </div>
          </form>
          {isModalOpen && (
            <ImageModal
              image={modalImage}
              onClose={handleModalClose}
              open={previewOpen}
            />
          )}
        </div>
        <InspectionSuccess open={successIsOpen} onClose={LoginClose} />
      </div>
    </>
  );
};

export default Step2;
